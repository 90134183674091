'use client';

import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useRouter } from 'next/navigation';
import { z } from 'zod';

const homeFilterValidation = z.object({
  city: z.string().optional(),
  date: z.any().optional(),
  query: z.string().optional(),
});

export const useHomeFilter = () => {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  type HomeFilterForm = z.infer<typeof homeFilterValidation>;

  const form = useForm<HomeFilterForm>({
    defaultValues: {
      city: '',
      date: '',
      query: '',
    },
  });

  const onSubmit = (data: HomeFilterForm) => {
    setLoading(true);

    const params = new URLSearchParams();

    if (data.city) {
      params.append('city', data.city);
    }

    if (data.date.from) {
      params.append(
        'from',
        new Date(data.date.from).toISOString().split('T')[0],
      );
    }

    if (data.date.to) {
      params.append('to', new Date(data.date.to).toISOString().split('T')[0]);
    }

    if (data.query) {
      params.append('query', data.query);
    }

    router.push(`/events?${params.toString()}`);
  };

  return {
    form,
    loading,
    onSubmit,
  };
};
