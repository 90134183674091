import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/swiper/swiper.css");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/swiper/modules/effect-cards.css");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/node_modules/swiper/modules/pagination.css");
;
import(/* webpackMode: "eager", webpackExports: ["DebugPanel"] */ "/home/vsts/work/1/s/src/components/debug/debug-panel.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/components/ui/carousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlipWords"] */ "/home/vsts/work/1/s/src/components/ui/flip-words.tsx");
;
import(/* webpackMode: "eager" */ "/home/vsts/work/1/s/src/containers/home/home-filter/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HomeSwiper"] */ "/home/vsts/work/1/s/src/containers/home/home-swiper.tsx");
