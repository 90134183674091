'use client';

import { useState } from 'react';

import { BugIcon, X } from 'lucide-react';

interface Props {
  data: any;
}

export const DebugPanel = ({ data }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='fixed bottom-4 right-4 z-50'>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className='rounded-full bg-secondary p-3 text-white shadow-lg transition-all duration-300 hover:bg-zinc-800'
        title='Toggle Debug Panel'
      >
        <BugIcon size={24} />
      </button>

      {isOpen && (
        <>
          <div
            className='fixed inset-0 z-40 bg-black opacity-50'
            onClick={() => setIsOpen(false)}
          />

          <div className='fixed bottom-20 right-0 z-50 w-full max-w-2xl rounded-lg border border-zinc-700 bg-zinc-900 text-white shadow-xl md:right-4'>
            <div className='flex items-center justify-between border-b border-zinc-700 p-4'>
              <h3 className='text-lg font-semibold'>Debug Panel</h3>
              <button
                onClick={() => setIsOpen(false)}
                className='text-zinc-400 transition-colors hover:text-white'
              >
                <X size={20} />
              </button>
            </div>
            <div className='max-h-[70vh] overflow-y-auto p-4'>
              <pre className='whitespace-pre-wrap font-mono text-sm text-emerald-400'>
                {JSON.stringify(data, null, 2)}
              </pre>
            </div>
            <div className='rounded-b-lg border-t border-zinc-700 bg-zinc-800 p-4'>
              <p className='text-xs text-zinc-400'>
                Allowed users only. This panel is for debugging purposes only.
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
